import { render, staticRenderFns } from "./KDATechPython.vue?vue&type=template&id=529ea127&scoped=true"
import script from "./KDATechPython.vue?vue&type=script&lang=js"
export * from "./KDATechPython.vue?vue&type=script&lang=js"
import style0 from "./KDATechPython.vue?vue&type=style&index=0&id=529ea127&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "529ea127",
  null
  
)

export default component.exports